import PageTabs from 'component/PageTabs'
import Short from './components/Short'
import Video from './components/Video'
import Comic from './components/Comic'
import Novel from './components/Novel'
import { useContentTab } from 'hooks/useContentTab'
import { report, useReportAppScreen, WorkSectionProvider } from 'hooks/useAnalytics'

export default function BrowseHistory({ ...props }) {
  const { tabs, tabContents } = useContentTab({
    video: Video,
    short: Short,
    comic: Comic,
    novel: Novel,
  })

  useReportAppScreen({
    SerialNumber: 1,
    Event: 'app_screen',
    Trigger: '觀看紀錄頁面',
    Parameters: 'page_title',
    Value: 'view_footprints_page',
  })

  return (
    <WorkSectionProvider
      SerialNumber={5}
      Event="footprints_click"
      Trigger="點擊觀看紀錄內容"
      Parameters="view_history_content_id"
    >
      <PageTabs
        {...props}
        title="浏览纪录"
        tabs={tabs}
        onClose={({ close }) => {
          close()
          report({
            SerialNumber: 6,
            Event: 'footprints_click',
            Trigger: '返回',
            Parameters: 'content',
            Value: 'go_back',
          })
        }}
        onClickTab={(id) => {
          if (id === 'video') {
            report({
              SerialNumber: 2,
              Event: 'footprints_click',
              Trigger: '點擊長視頻',
              Parameters: 'content',
              Value: 'long_video',
            })
          }
          if (id === 'short') {
            report({
              SerialNumber: 3,
              Event: 'footprints_click',
              Trigger: '點擊短視頻',
              Parameters: 'content',
              Value: 'short_video',
            })
          }
          if (id === 'comic') {
            report({
              SerialNumber: 4,
              Event: 'footprints_click',
              Trigger: '點擊漫畫',
              Parameters: 'content',
              Value: 'comic',
            })
          }
        }}
      >
        {tabContents}
      </PageTabs>
    </WorkSectionProvider>
  )
}
