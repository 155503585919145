import { useEffect, useState } from 'react'

import { fetchVideoList, fetchVideoTotalQty } from 'api'

import VideoBox from 'component/VideoBox'

import { Wrapper, Title } from './Styled'
import { CmsGridWrapper } from 'commonStyledComponents'
import { fetchRandomWithMultiPage } from 'utils/randomList'
import { catchPromiseCancel } from 'utils/catchPromiseCancel'
import AreaWrapper from 'component/AreaWrapper'
import { WorkSectionProvider } from 'hooks/useAnalytics'

export default function RelativeCategoryVideo({ videoInfo }) {
  const currentVideo = videoInfo.id
  const [relativeVideoList, setRelativeVideoList] = useState([])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const categoryIds = videoInfo?.category_ids

    const params = {
      category_ids: categoryIds?.join(','),
      pageSize: 6,
      signal,
    }

    // 隨機結果
    fetchRandomWithMultiPage(fetchVideoTotalQty, fetchVideoList, params)
      .then((data) => setRelativeVideoList(data))
      .catch(catchPromiseCancel)

    return () => {
      controller.abort()
    }
  }, [videoInfo])

  const filteredVideos = relativeVideoList.filter((i) => i.id !== currentVideo)

  if (filteredVideos.length === 0) return null

  return (
    <WorkSectionProvider
      SerialNumber={19}
      Event="video_play_click"
      Trigger="點擊她的其他作品/相關視屏影片"
      Parameters="click_related_video_id"
    >
      <Wrapper>
        <AreaWrapper>
          <Title hasDecoration text="相关视频" />

          <CmsGridWrapper>
            {filteredVideos?.map((item, i) => (
              <VideoBox key={i} videoInfo={item} />
            ))}
          </CmsGridWrapper>
        </AreaWrapper>
      </Wrapper>
    </WorkSectionProvider>
  )
}
