import Video from './components/Video'
import Short from './components/Short'
import PageTabs from 'component/PageTabs'
import { useContentTab } from 'hooks/useContentTab'
import { report, useReportAppScreen, WorkSectionProvider } from 'hooks/useAnalytics'

export default function BrowseFavourite({ ...props }) {
  const { tabs, tabContents } = useContentTab({ video: Video, short: Short }, { filter: [1, 2] })

  useReportAppScreen({
    SerialNumber: 1,
    Event: 'app_screen',
    Trigger: '我的喜歡頁面',
    Parameters: 'page_title',
    Value: 'my_likes_page',
  })

  return (
    <WorkSectionProvider
      SerialNumber={4}
      Event="my_likes_click"
      Trigger="點擊我的喜歡內容 "
      Parameters="my_likes_content_id"
    >
      <PageTabs
        {...props}
        title="我的喜欢"
        tabs={tabs}
        onClose={({ close }) => {
          close()
          report({
            SerialNumber: 5,
            Event: 'my_likes_click',
            Trigger: '返回',
            Parameters: 'content',
            Value: 'go_back',
          })
        }}
        onClickTab={(id) => {
          if (id === 'video') {
            report({
              SerialNumber: 2,
              Event: 'my_likes_click',
              Trigger: '點擊長視頻',
              Parameters: 'content',
              Value: 'long_video',
            })
          }
          if (id === 'short') {
            report({
              SerialNumber: 3,
              Event: 'my_likes_click',
              Trigger: '點擊短視頻',
              Parameters: 'content',
              Value: 'short_video',
            })
          }
        }}
      >
        {tabContents}
      </PageTabs>
    </WorkSectionProvider>
  )
}
