import { useDispatch, useSelector } from 'react-redux'
//
import { selectUserInfo } from 'redux/selector/user'
//
import useOpenModal from './hooks/useOpenModal'
import useMoreServices from './hooks/useMoreServices'

import { PageWrapper } from 'commonStyledComponents'
import Carousel from './component/Carousel'
import {
  Wrapper,
  IconSystemConfig,
  Area,
  Area3,
  Info,
  Avatar,
  NameBox,
  IDBox,
  RegisterBox,
  SmallButton,
  Area3Title,
  Area2,
  LongButton,
  LongButtonText,
  Area2Separator,
  VipInfoBox,
  ConfigButton,
  PageDecoration,
  CopyButton,
} from './Styled'
import { useHasPayment } from 'hooks/useHasPayment'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { parseVipInfo } from 'utils/userUtils'
import PullToRefresh from 'react-simple-pull-to-refresh'
import { fetchUserInfo } from 'api'
import actions from 'redux/action'
import { useToastAlert } from 'hooks/useToastAlert'
import { usePageActivated } from 'app-layered-layout/usePageActivated'
import PagePullLoad from 'component/Loading/PagePullLoad'
import { report, useReportAppScreen } from 'hooks/useAnalytics'

export default function Profile() {
  const userInfo = useSelector(selectUserInfo)
  const activated = usePageActivated()

  const {
    openSystemConfigModal,
    openLoginModal,
    openFavouriteModal,
    openBusinessRelationshipModal,
    openCollectionModal,
    openBrowseHistoryModal,
    openEnableVipModal,
    openAppCenterModal,
    openSharePageModal,
    openPurchaseHistoryModal,
    openWalletModal,
  } = useOpenModal()
  const { smallBoxItems, longBoxItems } = useMoreServices({
    openFavouriteModal,
    openBusinessRelationshipModal,
    openCollectionModal,
    openBrowseHistoryModal,
    openEnableVipModal,
    openAppCenterModal,
    openSharePageModal,
    openPurchaseHistoryModal,
    openWalletModal,
  })

  /** userInfo.type 為 1, 是遊客 */
  const isUserLogin = userInfo.type !== 1

  const hasPayment = useHasPayment()
  const vipInfo = useMemo(() => parseVipInfo(userInfo), [userInfo])
  const username = hasPayment ? (userInfo.vip_at ? 'VIP会员' : userInfo.name) : '永久免费'
  const dispatch = useDispatch()
  const { toast } = useToastAlert()
  // const defaultName = hasPayment ? userInfo.name : '永久免費'

  const onRefresh = useCallback(async () => {
    const userInfo = await fetchUserInfo()
    dispatch(actions.updateUser(userInfo))
  }, [dispatch])

  const handleCopyUid = useCallback(async () => {
    report({
      SerialNumber: 4,
      Event: 'account_click',
      Trigger: '複製ID',
      Parameters: 'content',
      Value: 'copy_id',
    })

    if (userInfo?.show_id && navigator.clipboard) {
      await navigator.clipboard.writeText(userInfo?.show_id)
      toast('已复制ID至剪贴簿')
    }
  }, [toast, userInfo?.show_id])

  const [usingFlag, setUsingFlag] = useState(() => localStorage.getItem('DEBUG') != null)

  useEffect(() => {
    if (activated) {
      setUsingFlag(localStorage.getItem('DEBUG') != null)
    }
  }, [activated])

  useReportAppScreen({
    SerialNumber: 1,
    Event: 'app_screen',
    Trigger: '帳戶頁面',
    Parameters: 'page_title',
    Value: 'account_page',
  })

  return (
    <PageWrapper>
      {process.env.REACT_APP_HAS_PROFILE_BACKGROUND ? <PageDecoration /> : null}
      <Wrapper>
        <PullToRefresh
          onRefresh={onRefresh}
          pullingContent={<PagePullLoad paused />}
          refreshingContent={<PagePullLoad />}
        >
          <ConfigButton onClick={openSystemConfigModal} className="no-prevent-swipe">
            <IconSystemConfig />
          </ConfigButton>
          <Area>
            <Avatar>
              <img
                alt={'man'}
                width={'100%'}
                height={'100%'}
                src={`${process.env.PUBLIC_URL}/profile-icon/branding.png`}
              />
            </Avatar>
            <Info>
              {/** 用戶名稱 */}
              <NameBox>{username}</NameBox>
              {/** 用戶ID */}
              <IDBox>
                ID: {userInfo?.show_id}
                <CopyButton onClick={handleCopyUid}></CopyButton>
              </IDBox>
              {hasPayment && <VipInfoBox>{vipInfo.expireText}</VipInfoBox>}
            </Info>

            {/** 如果用戶未登入 */}
            {!isUserLogin && <RegisterBox onClick={openLoginModal}>注册/登入</RegisterBox>}
          </Area>

          {longBoxItems?.length > 0 && (
            <Area2>
              {longBoxItems.map((item, i, arr) => (
                <Fragment key={i}>
                  <LongButton className={item.icon} onClick={item.handleClick}>
                    <LongButtonText>{item.title}</LongButtonText>
                  </LongButton>
                  {i !== arr.length - 1 && <Area2Separator />}
                </Fragment>
              ))}
            </Area2>
          )}

          {/** 個人中心廣告區 */}
          <Carousel />

          {/** 推薦專區 目前還未開放先註解 */}
          {/*<Area2>*/}
          {/*  {largeBoxItems?.map(({ title, content }, i) => {*/}
          {/*    const imageName = i + 1;*/}
          {/*    const imagePath = `${process.env.PUBLIC_URL}/profile-icon/large/${imageName}.png`;*/}

          {/*    return (*/}
          {/*      <LargeButton key={i}>*/}
          {/*        <img src={imagePath} />*/}
          {/*        <TextContent>*/}
          {/*          <LargeTitle>{title}</LargeTitle>*/}
          {/*          <LargeContent>{content}</LargeContent>*/}
          {/*        </TextContent>*/}
          {/*      </LargeButton>*/}
          {/*    );*/}
          {/*  })}*/}
          {/*</Area2>*/}
          {hasPayment && <Area3Title>更多服务</Area3Title>}
          <Area3>
            {smallBoxItems?.map(({ title, iconComponent, handleClick = null }, i) => {
              const imageName = i + 1
              const imagePath = `${process.env.PUBLIC_URL}/profile-icon/small/${imageName}.png`
              const Icon = iconComponent
              return (
                <SmallButton key={i} onClick={handleClick}>
                  {Icon ? <Icon /> : <img alt="" src={imagePath} />}
                  <span>{title}</span>
                </SmallButton>
              )
            })}
          </Area3>
          {usingFlag ? (
            <div style={{ color: 'red', lineHeight: '1.5' }}>
              警告：除錯選項使用中
              <br /> Warning: using debug flags
            </div>
          ) : null}
        </PullToRefresh>
      </Wrapper>
    </PageWrapper>
  )
}
