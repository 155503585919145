import { report } from 'hooks/useAnalytics'
import { useNavigateTo } from 'hooks/useNavigateTo'

export default function useOpenModal() {
  const {
    navigateToPurchaseHistory,
    navigateToBrowseCollection,
    navigateToBrowseFavorite,
    navigateToBrowseHistory,
    navigateToBusinessRelationship,
    navigateToAppCenter,
    navigateToLogin,
    navigateToSystemConfig,
    navigateToShare,
    navigateToWallet,
    navigateToEnableVip,
  } = useNavigateTo()

  const openSystemConfigModal = () => {
    report({
      SerialNumber: 2,
      Event: 'account_click',
      Trigger: '系統設置',
      Parameters: 'content',
      Value: 'system_settings',
    })

    navigateToSystemConfig({})
  }

  const openLoginModal = () => {
    report({
      SerialNumber: 3,
      Event: 'account_click',
      Trigger: '註冊/登入',
      Parameters: 'content',
      Value: 'register_login',
    })
    navigateToLogin({})
  }

  const openPurchaseHistoryModal = () => {
    navigateToPurchaseHistory()
  }

  const openFavouriteModal = () => {
    report({
      SerialNumber: 7,
      Event: 'account_click',
      Trigger: '我的喜歡',
      Parameters: 'content',
      Value: 'my_likes',
    })
    navigateToBrowseFavorite({})
  }

  const openBusinessRelationshipModal = () => {
    report({
      SerialNumber: 10,
      Event: 'account_click',
      Trigger: '商務合作',
      Parameters: 'content',
      Value: 'business_cooperation',
    })
    navigateToBusinessRelationship({})
  }

  const openCollectionModal = () => {
    report({
      SerialNumber: 8,
      Event: 'account_click',
      Trigger: '我的收藏',
      Parameters: 'content',
      Value: 'my_bookmarks',
    })
    navigateToBrowseCollection({})
  }

  const openBrowseHistoryModal = () => {
    report({
      SerialNumber: 6,
      Event: 'account_click',
      Trigger: '我的足跡',
      Parameters: 'content',
      Value: 'my_footprints',
    })
    navigateToBrowseHistory({})
  }

  const openEnableVipModal = () => {
    navigateToEnableVip({})
  }

  const openAppCenterModal = () => {
    report({
      SerialNumber: 9,
      Event: 'account_click',
      Trigger: '應用中心',
      Parameters: 'content',
      Value: 'app_center',
    })
    navigateToAppCenter({ asPage: true })
  }

  const openSharePageModal = () => {
    report({
      SerialNumber: 11,
      Event: 'account_click',
      Trigger: '推廣分享',
      Parameters: 'content',
      Value: 'promotional_sharing',
    })
    navigateToShare()
  }

  const openWalletModal = () => {
    navigateToWallet({})
  }
  return {
    openSystemConfigModal,
    openLoginModal,
    openPurchaseHistoryModal,
    openFavouriteModal,
    openCollectionModal,
    openBrowseHistoryModal,
    openEnableVipModal,
    openAppCenterModal,
    openBusinessRelationshipModal,
    openSharePageModal,
    openWalletModal,
  }
}
