import SearchField from '../SearchField'
import useViaSpecific from './hooks/useViaSpecific'

import { Wrapper, ContentWrapper, SearchBox, Controller, IconBackWrapper } from './Styled'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSearchHistory } from 'hooks/useSearchHistory'
import { HeaderWrap } from 'commonStyledComponents'
import Tabs from 'component/Tabs'
import { TabsType } from 'component/Tabs/Styled'
import { IconBack } from 'component/PageModalHeaderBox/Styled'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import { usePageActivated } from 'app-layered-layout/usePageActivated'
import { useContentTab } from 'hooks/useContentTab'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SearchTab } from './component/SearchTab'
import { report, useReportAppScreen, WorkSectionProvider } from 'hooks/useAnalytics'

const INACTIVE_STATE = {
  hasMore: false,
  isLoading: false,
  isPlaceHolder: true,
  list: [],
}

/**
 *
 * @param {number[]} searchTypes
 * @returns
 */
export default function ViaSpecific() {
  const navigate = useAppNavigate()

  const { tabs } = useContentTab()

  const { state, searchTitle, onSearch, onCancel, handleFetchVideoList, onHistoryClick, mode, onChangeMode } =
    useViaSpecific()

  const [text, setText] = useState(searchTitle ?? '')

  const pageActivated = usePageActivated()

  useEffect(() => {
    if (pageActivated) {
      setText(searchTitle ?? '')
    }
  }, [pageActivated, searchTitle])

  const { add } = useSearchHistory()

  const onSearchWithHistory = useCallback(
    (word) => {
      if (!word) {
        return
      }

      add(mode, word)
      return onSearch(word)
    },
    [add, mode, onSearch]
  )

  const onChangeModeWithHistory = useCallback(
    (mode) => {
      if (searchTitle) {
        add(mode, searchTitle)
      }
      onChangeMode(mode)
    },
    [add, onChangeMode, searchTitle]
  )

  const onRemoveContent = () => {
    // cancel the search if we are doing search
    if (searchTitle) {
      return onCancel()
    }

    // only clear if we are not searching
    setText('')
  }

  const onClickIconBack = () => {
    // cancel the search if we are doing search
    if (searchTitle) {
      return onCancel()
    }

    report({
      SerialNumber: 10,
      Event: 'search_click',
      Trigger: '返回',
      Parameters: 'content',
      Value: 'go_back',
    })
    // leave if we are not searching
    return navigate(-1)
  }

  const shortInfo = useMemo(() => {
    if (mode !== 'short') {
      return {
        list: [],
      }
    } else {
      return {
        list: state.list,
      }
    }
  }, [mode, state.list])

  const tabContentRef = useRef()

  const onClickTab = useCallback(
    (mode) => {
      const codeMap = {
        video: 5,
        short: 6,
        comic: 7,
      }
      const triggerMap = {
        video: '點擊長視頻',
        short: '點擊短視頻',
        comic: '點擊漫畫',
      }
      const map = {
        video: 'long_video',
        short: 'short_vide0',
        comic: 'novel',
      }
      if (codeMap[mode] != null) {
        report({
          SerialNumber: codeMap[mode],
          Event: 'search_click',
          Trigger: triggerMap[mode],
          Parameters: 'content',
          Value: map[mode],
        })
      }
      const newIndex = tabs.findIndex((t) => t.id === mode)
      tabContentRef.current?.swiper.slideTo(newIndex)
    },
    [tabs]
  )

  const onSlideChange = useCallback(
    (swiper) => {
      if (tabs[swiper.activeIndex].id !== mode) {
        console.log(tabs[swiper.activeIndex].id)
        onChangeModeWithHistory(tabs[swiper.activeIndex].id)
      }
    },
    [mode, onChangeModeWithHistory, tabs]
  )

  const initialSlide = useRef(tabs.findIndex((i) => i.id === mode))

  useReportAppScreen({
    SerialNumber: 1,
    Event: 'app_screen',
    Trigger: '搜尋頁面',
    Parameters: 'page_title',
    Value: 'search_page',
  })

  return (
    <WorkSectionProvider SerialNumber={11} Event="search_click" Trigger="點擊搜尋結果中內容">
      <Wrapper>
        <ContentWrapper>
          <HeaderWrap>
            <Controller>
              <IconBackWrapper onClick={onClickIconBack}>
                <IconBack />
              </IconBackWrapper>
              <SearchBox>
                <SearchField
                  placeholder={'输入关键字搜寻'}
                  value={text}
                  onChange={setText}
                  onSearch={onSearchWithHistory}
                  onRemoveContent={onRemoveContent}
                />
              </SearchBox>
            </Controller>
            {tabs.length > 1 && <Tabs type={TabsType.Underline} options={tabs} value={mode} onChange={onClickTab} />}
          </HeaderWrap>

          <Swiper
            ref={tabContentRef}
            spaceBetween={10}
            onSlideChange={onSlideChange}
            initialSlide={initialSlide.current}
            style={{ width: '100%', flex: '1 1 auto', minHeight: '0px' }}
            cssMode
          >
            {tabs.map((t) => (
              <SwiperSlide key={t.id}>
                <SearchTab
                  mode={t.id}
                  searchTitle={searchTitle}
                  state={mode === t.id ? state : INACTIVE_STATE}
                  handleFetchVideoList={handleFetchVideoList}
                  shortInfo={shortInfo}
                  onHistoryClick={onHistoryClick}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </ContentWrapper>
      </Wrapper>
    </WorkSectionProvider>
  )
}
