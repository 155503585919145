import { fetchAddComicCollectList, fetchComicChapters, fetchComicList, fetchRemoveComicCollectList } from 'api'
import { COMIC_SERIAL_ENDED, COMIC_SERIAL_ONGOING } from 'constant/comic'
import { DISABLED, ENABLED, TRUE } from 'constant/common'
import { useIntersectionObserver } from 'hooks/useIntersectionObserver'
import { useCallback, useEffect, useState } from 'react'
import Recommendations from './components/Recommendations'
import useComicChapters from './hooks/useComicChapters'
import {
  Wrapper,
  IconBack,
  Title,
  TitleCard,
  TopCover,
  WrapperInner,
  TagArea,
  Tag,
  Desc,
  StatusBar,
  TitleObserveArea,
  StatusBarContent,
  Status,
  ChapterList,
  ChapterItem,
  ChapterTrigger,
  Footer,
  StartReading,
  CollectionButton,
  StyledAnimatedIcon,
  NoStartReading,
  StyledAesImage,
  CoverDecoration,
  TopCoverFiller,
} from './Styled'
import saveIconData from './animated-icons/save_outline.json'
import { Chapters } from './components/Chapters'
import { useOpenComicChapter } from './hooks/useOpenComicChapter'
import { useDispatch, useSelector } from 'react-redux'
import { RESET_COMIC_COLLECTION } from 'redux/constant/comicCollection'
import useAlertWorksActionResult from 'hooks/useAlertWorksActionResult'
import ButtonSearchMore from 'component/ButtonSearchMore'
import { useSharedCache } from 'hooks/useDirtyWorkData'
import { selectStation } from 'redux/selector/app'
import { useNavigateTo, useParamsOfPage } from 'hooks/useNavigateTo'
import { usePageActivated } from 'app-layered-layout/usePageActivated'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import { report, useReportAppScreen } from 'hooks/useAnalytics'

export default function ViewComic() {
  const { posterImg, comicInfo: initialComicInfo } = useParamsOfPage('comic')
  const pageActivated = usePageActivated()
  const navigate = useAppNavigate()

  const { addDirtyData, useMappedData } = useSharedCache()
  const station = useSelector(selectStation)
  const dispatch = useDispatch()
  const [comicInfo] = useMappedData('comic', [initialComicInfo])

  useEffect(() => {
    // also trigger update when page re-activate
    if (!pageActivated) {
      return
    }
    const con = new AbortController()
    const sig = con.signal

    fetchComicList({
      signal: sig,
      detail: TRUE,
      id: initialComicInfo.id,
    }).then((res) => {
      addDirtyData('comic', res.data)
    })

    return () => {
      con.abort()
    }
  }, [addDirtyData, initialComicInfo.id, pageActivated])

  const {
    id,
    favorite,
    tag_ids = [],
    tag_names = [],
    serial,
    last_read_chapter_id,
    latest_chapter_prefix_name,
  } = comicInfo

  const [lastReadChapterInfo, setLastReadChapterInfo] = useState(null)

  useEffect(() => {
    if (!last_read_chapter_id || Number(last_read_chapter_id) === 0) {
      setLastReadChapterInfo(null)
      return
    }
    const con = new AbortController()
    const sig = con.signal
    fetchComicChapters({ id: last_read_chapter_id, signal: sig }).then((info) => {
      setLastReadChapterInfo(info.data[0])
    })
    return () => {}
  }, [last_read_chapter_id])

  const expandRange = (current, [rangeMin, rangeMax], [toMin, toMax]) => {
    const expanded = ((current - rangeMin) / (rangeMax - rangeMin)) * (toMax - toMin) + toMin
    const capped = Math.min(toMax, Math.max(toMin, expanded))
    return capped
  }

  const { ref, containerRef, ratio } = useIntersectionObserver({
    threshold: Array.from({ length: 19 }).map((_, index) => (index + 1) / 19),
    defaultVisible: true,
  })

  const mappedRatio = expandRange(ratio, [1, 0.95], [0, 1])

  const { state, fetchMore } = useComicChapters({
    comic_id: id,
  })

  const [chapterListOpened, setChapterListOpened] = useState(false)

  const {
    ref: loadMoreRef,
    containerRef: loadMoreContainerRef,
    visible,
  } = useIntersectionObserver({
    defaultVisible: false,
    cb: (entries) => {
      for (const e of entries) {
        if (e.isIntersecting) {
          fetchMore()
        }
      }
    },
  })

  useEffect(() => {
    if (state.hasMore && state.list.length === 0) {
      fetchMore()
    }
  }, [fetchMore, state.nextPage, state.hasMore, state.list.length, visible])

  const { navigateToViewMore } = useNavigateTo()
  const openViewMore = (tagId, tagName) => {
    navigateToViewMore({
      params: {
        tag_ids: tagId,
      },
      headerTitle: tagName,
      type: 'comic',
    })
  }
  const { openComicChapter } = useOpenComicChapter()

  const onOpenChapter = useCallback(
    (chapter) => {
      openComicChapter(comicInfo.id, chapter)
    },
    [comicInfo.id, openComicChapter]
  )

  const alerts = useAlertWorksActionResult()

  const setFavorite = async (value) => {
    if (value) {
      await fetchAddComicCollectList({ comic_id: id })
    } else {
      await fetchRemoveComicCollectList({ comic_id: id })
    }

    dispatch({ type: RESET_COMIC_COLLECTION })

    const res = await fetchComicList({
      detail: TRUE,
      id: initialComicInfo.id,
    })

    addDirtyData('comic', res.data)

    if (value) {
      alerts.alertSaved()
    } else {
      // alerts.alertUnsaved()
    }
  }

  const startReading = () => {
    if (lastReadChapterInfo) {
      onOpenChapter(lastReadChapterInfo)
      report({
        SerialNumber: 8,
        Event: 'comic_click',
        Trigger: '點擊續看',
        Parameters: 'content',
        Value: 'continue_reading',
      })
      return
    }

    if (state.list[0]) {
      onOpenChapter(state.list[0])
      report({
        SerialNumber: 7,
        Event: 'comic_click',
        Trigger: '點擊開始閱讀',
        Parameters: 'content',
        Value: 'start_reading',
      })
      return
    }
  }

  const canStartReading = lastReadChapterInfo != null || state.list[0] != null

  useReportAppScreen({
    SerialNumber: 1,
    Event: 'app_screen',
    Trigger: '漫畫頁面',
    Parameters: 'page_title',
    Value: 'comic_page',
  })

  return (
    <Wrapper>
      <WrapperInner ref={containerRef}>
        <TopCover
          style={{
            backgroundImage: `url(${posterImg})`,
          }}
        >
          {!posterImg && (
            <StyledAesImage
              decryptKey={station.cover_key}
              src={comicInfo.cover_url}
              style={{ objectPosition: '50% 0' }}
            />
          )}
          <CoverDecoration />
          <TopCoverFiller />
          <TitleCard>
            <Title>
              <TitleObserveArea ref={ref} />
              {comicInfo.title}
            </Title>
            {tag_ids.filter((id, index) => tag_names[index]).length > 0 && (
              <TagArea>
                {tag_ids
                  .filter((id, index) => tag_names[index])
                  .map((id, index) =>
                    tag_names[index] ? (
                      <Tag key={index} onClick={() => openViewMore(id, tag_names[index])}>
                        {tag_names[index]}
                      </Tag>
                    ) : null
                  )}
              </TagArea>
            )}
            {comicInfo.summary && (
              <Desc
                text={comicInfo.summary}
                onExpand={() => {
                  report({
                    SerialNumber: 2,
                    Event: 'comic_click',
                    Trigger: '點擊展開',
                    Parameters: 'content',
                    Value: 'expand',
                  })
                }}
              />
            )}
          </TitleCard>
        </TopCover>
        {canStartReading && (
          <>
            <Status text={serial === COMIC_SERIAL_ENDED ? '已完结' : serial === COMIC_SERIAL_ONGOING ? '连载中' : ''}>
              <ButtonSearchMore
                onClick={() => {
                  setChapterListOpened((v) => !v)
                  report({
                    SerialNumber: 3,
                    Event: 'comic_click',
                    Trigger: '點擊目錄',
                    Parameters: 'content',
                    Value: 'table_of_content',
                  })
                }}
                text={`更新到${latest_chapter_prefix_name}`}
                style={{ color: 'var(--page-view-comic--more-color)' }}
              />
            </Status>
            <ChapterList ref={loadMoreContainerRef}>
              {state.list.map((item, index) => (
                <ChapterItem
                  key={index}
                  active={Number(last_read_chapter_id) === 0 ? index === 0 : item.id === last_read_chapter_id}
                  onClick={() => {
                    onOpenChapter(item)
                    report({
                      SerialNumber: 4,
                      Event: 'comic_click',
                      Trigger: '點擊第幾話',
                      Parameters: 'chapter_id',
                      Value: item.id,
                    })
                  }}
                >
                  {item.prefix_name + (item.title ? ` - ${item.title}` : '')}
                </ChapterItem>
              ))}
              {state.hasMore && <ChapterTrigger ref={loadMoreRef} />}
            </ChapterList>
          </>
        )}
        {tag_ids.length > 0 && <Recommendations tag_ids={tag_ids} />}
      </WrapperInner>
      <Footer>
        <CollectionButton
          onClick={() => {
            setFavorite(favorite === DISABLED)
            if (favorite === DISABLED) {
              report({
                SerialNumber: 5,
                Event: 'comic_click',
                Trigger: '點擊收藏',
                Parameters: 'content',
                Value: 'directory',
              })
            } else {
              report({
                SerialNumber: 6,
                Event: 'comic_click',
                Trigger: '點擊取消收藏',
                Parameters: 'content',
                Value: 'remove_bookmark',
              })
            }
          }}
        >
          <StyledAnimatedIcon animationData={saveIconData} active={favorite === ENABLED} />
          {favorite === ENABLED ? '已收藏' : '收藏'}
        </CollectionButton>
        {canStartReading ? (
          <StartReading onClick={startReading}>
            {lastReadChapterInfo ? `续看${lastReadChapterInfo.prefix_name}` : '开始阅读'}
          </StartReading>
        ) : (
          <NoStartReading>暂无章节</NoStartReading>
        )}
      </Footer>
      <StatusBar>
        <StatusBarContent style={{ opacity: mappedRatio }}>{comicInfo.title}</StatusBarContent>
        <IconBack
          onClick={() => {
            navigate(-1)
            report({
              SerialNumber: 11,
              Event: 'comic_click',
              Trigger: '返回',
              Parameters: 'content',
              Value: 'go_back',
            })
          }}
        />
      </StatusBar>
      <Chapters
        title={`更新到${latest_chapter_prefix_name}`}
        comicId={id}
        disabled={!pageActivated}
        isOpen={chapterListOpened}
        onClose={() => setChapterListOpened(false)}
        activeChapter={last_read_chapter_id}
        onOpenChapter={onOpenChapter}
      />
    </Wrapper>
  )
}
