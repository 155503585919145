import { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { TextContainer, OverflowLabel, OverflowLabelDecor } from './Styled'

const ExpandableMultiLineText = ({
  text,
  lineCount = 3,
  style,
  backgroundColor = 'var(--main-background)',
  debug = false,
  onExpand: onExpandParent,
  ...props
}) => {
  const segmentWrapRef = useRef()
  const workSegmenterRef = useRef()
  const readMoreRef = useRef()

  const [boxes, setBoxes] = useState([])
  const [offset, setOffset] = useState({ x: 0, y: 0 })
  const [labelPos, setLabelPos] = useState({ x: 0, y: 0 })
  const [size, setSize] = useState(null)
  const [maxHeight, setMaxHeight] = useState(0)
  const [atEnd, setAtEnd] = useState(false)

  const [needExpand, setNeedExpand] = useState(true)
  const [expanded, setExpanded] = useState(false)

  const onExpand = useCallback(() => {
    setExpanded(true)
    onExpandParent?.()
  }, [onExpandParent])

  useLayoutEffect(() => {
    if (segmentWrapRef.current == null) {
      return
    }
    /**
     * @type {ResizeObserverCallback}
     */
    const handler = (entries) => {
      for (const e of entries) {
        setSize({
          width: e.contentRect.width,
          height: e.contentRect.height,
        })
      }
    }
    const observer = new ResizeObserver(handler)

    observer.observe(segmentWrapRef.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  useLayoutEffect(() => {
    /** @type {HTMLDivElement} */
    const readMore = readMoreRef.current
    /** @type {HTMLDivElement} */
    const wrap = segmentWrapRef.current
    /** @type {HTMLSpanElement} */
    const el = workSegmenterRef.current

    if (readMore == null || wrap == null || el == null) {
      return
    }

    const wrapRect = wrap.getBoundingClientRect()
    const readMoreRect = readMore.getBoundingClientRect()
    const rect = el.getBoundingClientRect()
    const offsetX = rect.x - wrapRect.x
    const offsetY = rect.y - wrapRect.y
    setOffset({ x: offsetX, y: offsetY })

    const boxes = el.getClientRects()
    const wraps = [...boxes].map((b) => ({
      x: b.x - rect.x,
      y: b.y - rect.y,
      yCenter: b.y - rect.y + b.height / 2,
      width: b.width,
      height: b.height,
    }))
    setBoxes(wraps)

    const lineHeight = Number(window.getComputedStyle(el).lineHeight.replace('px', ''))
    const hasOverflow = rect.height - lineHeight / 2 > lineHeight * lineCount

    if (!hasOverflow) {
      setNeedExpand(false)
      return
    }

    const lastVisibleRow = wraps.filter((i) => {
      return i.y > (lineCount - 1 - 0.5) * lineHeight && i.y < (lineCount - 1 + 0.5) * lineHeight
    })

    if (lastVisibleRow.length === 0) {
      return
    }

    const lastLineEnd = Math.max(...lastVisibleRow.map((i) => i.x + i.width))
    const appendAtEnd = rect.width - lastLineEnd > readMoreRect.width

    const x = appendAtEnd ? lastLineEnd : lastLineEnd - readMoreRect.width
    const y = lastVisibleRow[lastVisibleRow.length - 1].yCenter
    const res = { x, y }
    // console.log(res)
    setAtEnd(appendAtEnd)
    setLabelPos(res)
    setMaxHeight(lineHeight * lineCount)
  }, [lineCount, text, size?.width, size?.height])

  return (
    <TextContainer
      {...props}
      ref={segmentWrapRef}
      style={{
        ...(style ?? {}),
        '--line-count': String(lineCount),
        '--background-color': backgroundColor,
        maxHeight: needExpand ? (expanded ? 'none' : `${maxHeight}px`) : 'none',
      }}
      onClick={onExpand}
    >
      <span ref={workSegmenterRef}>{text}</span>
      {debug &&
        boxes.map((i, id) => (
          <span
            key={id}
            style={{
              display: 'block',
              position: 'absolute',
              left: `${i.x + offset.x}px`,
              top: `${i.y + offset.y}px`,
              width: `${i.width}px`,
              height: `${i.height}px`,
              background: 'rgba(255, 0, 0, 0.2)',
              pointerEvents: 'none',
            }}
          >
            {id}
          </span>
        ))}
      {!expanded && needExpand && (
        <OverflowLabel
          ref={readMoreRef}
          style={{
            left: `${labelPos.x + offset.x}px`,
            top: `${labelPos.y + offset.y}px`,
          }}
        >
          {!atEnd && <OverflowLabelDecor />}
          ...展开
        </OverflowLabel>
      )}
    </TextContainer>
  )
}

export default ExpandableMultiLineText
