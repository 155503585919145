import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { selectStation } from 'redux/selector/app'

import { useNavigationLocalData } from './hooks/useNavigationLocalData'

import { Wrapper, TagA, Nav, NavIcon, NavAesIcon, NavText } from './Styled'
import { useCallback } from 'react'
import { report } from 'hooks/useAnalytics'
import { NAV_ANALYTIC_PARAMS } from './analyticConstants'

/** 元件 - Bottom Navigation */
export default function Navigation() {
  const location = useLocation()
  const { navContent } = useNavigationLocalData()
  const station = useSelector(selectStation)

  const onNavClick = useCallback(
    (id) => {
      const name = location.pathname.replace(/^\//, '')
      if (NAV_ANALYTIC_PARAMS[name]) {
        report({
          ...NAV_ANALYTIC_PARAMS[name],
          Trigger: '點擊nav',
          Parameters: 'nav_id',
          Value: id,
        })
      }
    },
    [location]
  )

  return (
    <Wrapper>
      {navContent?.map(({ name, imageName, navLink, dynamicIconActive, dynamicIconInactive, search, id }, i) => {
        const isNavActive = location?.pathname === navLink && location.search === search

        return (
          <TagA key={i} to={navLink + search} onClick={() => onNavClick(id)} replace>
            <Nav>
              {!dynamicIconActive ? (
                <NavIcon active={isNavActive} imageName={imageName} />
              ) : (
                <NavAesIcon
                  source={process.env.REACT_APP_NAV_IMAGE_SOURCE}
                  decryptKey={station.cover_key}
                  src={isNavActive ? dynamicIconActive : dynamicIconInactive}
                />
              )}
              <NavText active={isNavActive}>{name}</NavText>
            </Nav>
          </TagA>
        )
      })}
    </Wrapper>
  )
}
