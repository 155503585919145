import { useCallback, useEffect, useRef, useState } from 'react'
import { useImmer } from 'use-immer'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
//
import {
  selectOfficialOrigin,
  selectOfficialOriginList,
  // selectPreviewOrigin,
  selectTagList,
  // selectProducerList,
} from 'redux/selector/app'
import useAlertNotDeveloped from 'hooks/useAlertNotDeveloped'
// import { selectUserInfo } from "redux/selector/user";
//
import { FALSE, TRUE } from 'constant/common'
import {
  fetchVideoList,
  fetchActorInfoList,
  fetchAddLikeList,
  fetchRemoveLikeList,
  fetchAddCollectList,
  fetchRemoveCollectList,
} from 'api'

// import AmountFormat from 'utils/amountFormat'
// import overwriteViewCount from "utils/overwriteViewCount";
// import { timeFormat } from "utils/timeFormat";
// import { videoPaymentType } from "constant/video";

import Carousel from './component/Carousel'
import RelativeActorVideo from './component/RelativeActorVideo'
import RelativeCategoryVideo from './component/RelativeCategoryVideo'

import likeIcon from './animated-icon/like_outline.json'
import saveIcon from './animated-icon/save_outline.json'

import {
  Wrapper,
  Content,
  VideoInfo,
  Text,
  Info,
  InfoItem,
  ShortcutArea,
  Shortcut,
  ShortcutIcon,
  OriginList,
  OriginItem,
  ShortcutOrigin,
  StyledVideoPlayer,
  StyledAnimatedIcon,
  ShortcutIconDropDown,
  TagArea,
  TagItem,
  TagAreaInner,
  VipLink,
  PaidLink,
  ActorTag,
  InfoItemLink,
} from './Styled'
import { RESET_FAVOURITE } from 'redux/constant/favourite'
import { RESET_COLLECTION } from 'redux/constant/collection'
import actions from 'redux/action'
import useAlertWorksActionResult from 'hooks/useAlertWorksActionResult'
import { useMutex } from 'hooks/useMutex'
import { selectUserInfo } from 'redux/selector/user'
import { videoPaymentType } from 'constant/video'
import { useOpenPurchaseVideoModal } from 'hooks/useOpenPurchaseVideoModel'
import useOpenModal from './hooks/useOpenModal'
import { useSharedCache } from 'hooks/useDirtyWorkData'
import { useNavigateTo, useParamsOfPage } from 'hooks/useNavigateTo'
import { usePageActivated } from 'app-layered-layout/usePageActivated'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import { useToastAlert } from 'hooks/useToastAlert'
import { catchPromiseCancel } from 'utils/catchPromiseCancel'
import { report, useReportAppScreen } from 'hooks/useAnalytics'

export default function ViewVideo() {
  const { addDirtyData, useMappedData } = useSharedCache()

  const pageActivated = usePageActivated()
  const videoInfo_ = useParamsOfPage('watch')
  const navigate = useAppNavigate()
  const { navigateToViewMore, navigateToActor, navigateToProducer, navigateToViewVideo } = useNavigateTo()

  /** @type {import("../../../types/api").SchemaVideoShow[]} */
  const [videoInfo] = useMappedData('video', [videoInfo_])

  const dispatch = useDispatch()
  const alertNotDeveloped = useAlertNotDeveloped()

  const [state, updateState] = useImmer({
    actorInfo: {},
    producerVideoCount: null,
    originalVideo: /** @type {null|import("../../../types/api").SchemaVideoShow} */ (null),
  })

  const [originListVisible, setOriginListVisible] = useState()

  const onToggleOriginList = useCallback((v) => {
    setOriginListVisible(v)
    report({
      SerialNumber: 9,
      Event: 'video_play_click',
      Trigger: '點擊線路',
      Parameters: 'content',
      Value: 'route',
    })
  }, [])

  const currentOfficialOrigin = useSelector(selectOfficialOrigin)
  const officialOriginList = useSelector(selectOfficialOriginList)

  const selectedOfficialOriginName =
    officialOriginList.find((i) => i.domain === currentOfficialOrigin)?.name ?? '高速线路1'

  const userInfo = useSelector(selectUserInfo)
  const tagList = useSelector(selectTagList)?.data

  const openPurchaseVideoModal = useOpenPurchaseVideoModal({ videoInfo })
  const { openEnableVipModal } = useOpenModal({ videoInfo })
  const {
    id,
    video_code,
    show_video_code,
    title,
    // video_length,
    // view_count,
    tag_ids,
    // producer_id,
    actor_ids,
    posterImg,
    producer_name,
    producer_icon,
    producer_id,
    // view_count,
    payment_type,
    product_coin,
    buy_video,
  } = videoInfo

  const validDisplayActorIds = actor_ids?.filter((i) => state.actorInfo[i] != null) ?? []

  const isVIP = userInfo.is_vip === TRUE
  const isBuyVideo = buy_video === TRUE

  /** 影片類型為金幣, 且用戶沒有買過影片 */
  const isCoinVideoNeverBuy = payment_type === videoPaymentType.COIN && !isBuyVideo

  /** 影片類型為VIP, 但用戶非VIP */
  const isVipVideoNotVip = payment_type === videoPaymentType.VIP && !isVIP

  /** 是否顯示番號 */
  const isDisplayVideoCode = show_video_code === TRUE

  useEffect(() => {
    if (!pageActivated) return

    const controller = new AbortController()
    const signal = controller.signal

    fetchVideoList({
      id: videoInfo_?.id,
      detail: TRUE,
      current: 1,
      pageSize: 1,
      signal,
    })
      .then((response) => {
        const data = response?.data[0]

        const producer_video_count = data?.producer_video_count
        const formatProducerVideoCount = producer_video_count ? producer_video_count : 0

        addDirtyData('video', response?.data)

        updateState((draft) => {
          draft.producerVideoCount = formatProducerVideoCount
        })
      })
      .catch(catchPromiseCancel)

    return () => {
      controller.abort()
    }
  }, [pageActivated, videoInfo_, updateState, addDirtyData])

  const actorIds = videoInfo?.actor_ids?.join(',') ?? ''
  const actorIdLength = videoInfo?.actor_ids?.length ?? 0

  useEffect(() => {
    if (actorIds === '') return
    const controller = new AbortController()
    const { signal } = controller
    /** 獲取演員資訊 */
    fetchActorInfoList({
      signal,
      ids: actorIds,
      pageSize: actorIdLength,
    })
      .then((response) => {
        const info = {}
        response?.data?.forEach((item, i) => {
          info[item?.id] = {
            id: item?.id,
            name: item?.name,
            icon_path: item?.icon_path,
          }
        })

        updateState((draft) => void (draft.actorInfo = info))
      })
      .catch(catchPromiseCancel)
    return () => {
      controller.abort()
    }
  }, [actorIdLength, actorIds, updateState])

  const ong_video = videoInfo?.ong_video

  useEffect(() => {
    if (!ong_video) return
    const controller = new AbortController()
    const { signal } = controller
    fetchVideoList({
      signal,
      video_code: ong_video,
    })
      .then((response) => {
        if (response?.data[0]) {
          updateState((draft) => void (draft.originalVideo = response?.data[0]))
        }
      })
      .catch(catchPromiseCancel)
    return () => {
      controller.abort()
    }
  }, [ong_video, updateState])

  const { toast } = useToastAlert()

  const handleOriginalVideoClick = useCallback(() => {
    if (state.originalVideo) {
      navigateToViewVideo(state.originalVideo)
    } else {
      toast('无法取得原片连结')
    }
  }, [navigateToViewVideo, state.originalVideo, toast])

  const alerts = useAlertWorksActionResult()
  const { wrap: wrapAddLike } = useMutex()
  const { wrap: wrapShare } = useMutex()

  const handelAddLikeList = wrapAddLike(async () => {
    const fetchUpdateApi = videoInfo?.like === TRUE ? fetchRemoveLikeList : fetchAddLikeList

    if (videoInfo?.like === TRUE) {
      report({
        SerialNumber: 12,
        Event: 'video_play_click',
        Trigger: '取消喜歡',
        Parameters: 'content',
        Value: 'remove_like',
      })
    } else {
      report({
        SerialNumber: 11,
        Event: 'video_play_click',
        Trigger: '點擊喜歡',
        Parameters: 'content',
        Value: 'like',
      })
    }

    await fetchUpdateApi({ video_id: videoInfo?.id })

    const latestData = (await fetchVideoList({ id: videoInfo?.id, detail: TRUE })).data

    addDirtyData('video', latestData)

    dispatch({ type: RESET_FAVOURITE })
  })

  const { wrap: wrapAddCollection } = useMutex()
  const handelAddCollectList = wrapAddCollection(async () => {
    const fetchUpdateApi = videoInfo?.favorite === TRUE ? fetchRemoveCollectList : fetchAddCollectList

    if (videoInfo?.favorite === TRUE) {
      report({
        SerialNumber: 14,
        Event: 'video_play_click',
        Trigger: '取消收藏',
        Parameters: 'content',
        Value: 'remove_bookmark',
      })
    } else {
      report({
        SerialNumber: 13,
        Event: 'video_play_click',
        Trigger: '點擊收藏',
        Parameters: 'content',
        Value: 'bookmark',
      })
    }

    await fetchUpdateApi({ video_id: videoInfo?.id })

    const latestData = (await fetchVideoList({ id: videoInfo?.id, detail: TRUE })).data

    addDirtyData('video', latestData)

    dispatch({ type: RESET_COLLECTION })
    if (videoInfo?.favorite === FALSE) {
      alerts.alertSaved()
    }
  })

  const handleShare = wrapShare(() => {
    report({
      SerialNumber: 15,
      Event: 'video_play_click',
      Trigger: '點擊分享',
      Parameters: 'content',
      Value: 'share',
    })
    alertNotDeveloped()
    // 等候兩秒再啟用顯示訊息
    return new Promise((res) => {
      setTimeout(() => {
        res()
      }, 2000)
    })
  })

  const openTag = (tagInfo) => {
    const { id, name } = tagInfo

    report({
      SerialNumber: 16,
      Event: 'video_play_click',
      Trigger: '選擇 hashtag',
      Parameters: 'content',
      Value: id,
    })

    const params = {
      tag_ids: id,
    }

    navigateToViewMore({
      params,
      headerTitle: name,
      type: 'video',
    })
  }

  function handleActorClick(actor) {
    navigateToActor({
      actorId: actor.id,
    })
    report({
      SerialNumber: 8,
      Event: 'video_play_click',
      Trigger: '點擊演員',
      Parameters: 'content',
      Value: actor.id,
    })
  }

  function handleProducerClick() {
    navigateToProducer({
      producerId: producer_id,
      producerImage: producer_icon,
      producerName: producer_name,
    })
  }

  useReportAppScreen({
    SerialNumber: 1,
    Event: 'app_screen',
    Trigger: '影片播放頁面',
    Parameters: 'page_title',
    Value: 'video_play_page',
  })

  const debounceTimer = useRef(null)

  const onUserSeek = useCallback((ev) => {
    clearTimeout(debounceTimer.current)
    const current = ev.to

    const FullS = Math.floor(current)
    const s = FullS % 60
    const FullM = ~~(FullS / 60)
    const m = FullM % 60
    const h = ~~(FullM / 60)
    const formatted =
      h.toString().padStart(2, '0') + ':' + m.toString().padStart(2, '0') + ':' + s.toString().padStart(2, '0')

    clearTimeout(debounceTimer.current)
    debounceTimer.current = setTimeout(() => {
      report({
        SerialNumber: 4,
        Event: 'video_play_click',
        Trigger: '點擊時間軸',
        Parameters: 'seek',
        Value: formatted,
      })
    }, 1000)
  }, [])

  const startTime = useRef(null)

  const onPlay = useCallback(() => {
    startTime.current = Date.now()
  }, [])

  const endPlayTime = useCallback(
    (end) => {
      if (startTime.current != null) {
        const duration = end - startTime.current
        console.log('[duration] ', duration)
        report({
          SerialNumber: 3,
          Event: 'duration',
          Trigger: '播放時長',
          Parameters: 'video_play_duration',
          Value: `${id}:${duration}`,
        })
        startTime.current = null
      }
    },
    [id]
  )

  const onPause = useCallback(() => {
    endPlayTime(Date.now())
  }, [endPlayTime])

  const onEnded = useCallback(() => {
    endPlayTime(Date.now())
  }, [endPlayTime])

  useEffect(() => {
    if (!pageActivated) {
      endPlayTime(Date.now())
    }
  }, [endPlayTime, pageActivated])

  const LEAVE_TIME = 2000
  const leaveTimer = useRef(null)
  useEffect(() => {
    clearTimeout(leaveTimer.current)

    return () => {
      const end = Date.now()
      leaveTimer.current = setTimeout(() => {
        endPlayTime(end)
      }, LEAVE_TIME)
    }
  })

  return (
    <Wrapper>
      {/* <IconBack onClick={() => closePageModal(uuid)} /> */}

      <Content>
        <div>
          {pageActivated && (
            <StyledVideoPlayer
              videoInfo={videoInfo}
              poster={posterImg}
              skipBigPlayIcon
              onPlay={onPlay}
              onPause={onPause}
              onEnded={onEnded}
              onBack={() => {
                navigate(-1)
                report({
                  SerialNumber: 20,
                  Event: 'video_play_click',
                  Trigger: '返回',
                  Parameters: 'content',
                  Value: 'go_back',
                })
              }}
              onClickPlay={() => {
                report({
                  SerialNumber: 2,
                  Event: 'video_play_click',
                  Trigger: '點擊播放影片',
                  Parameters: 'content',
                  Value: 'play_video',
                })
              }}
              onClickPause={() => {
                report({
                  SerialNumber: 3,
                  Event: 'video_play_click',
                  Trigger: '點擊暫停影片',
                  Parameters: 'content',
                  Value: 'pause_video',
                })
              }}
              onClickFullScreen={() => {
                report({
                  SerialNumber: 5,
                  Event: 'video_play_click',
                  Trigger: '點擊全螢幕',
                  Parameters: 'content',
                  Value: 'full_screen',
                })
              }}
              onClickShowUi={() => {
                report({
                  SerialNumber: 6,
                  Event: 'video_play_click',
                  Trigger: '點擊影片用來顯示播放控制',
                  Parameters: 'content',
                  Value: 'show_playback_controls',
                })
              }}
              onClickHideUi={() => {
                report({
                  SerialNumber: 7,
                  Event: 'video_play_click',
                  Trigger: '點擊影片用來關閉播放控制',
                  Parameters: 'content',
                  Value: 'close_playback_controls',
                })
              }}
              onUserSeek={onUserSeek}
            />
          )}
        </div>

        {isVipVideoNotVip && <VipLink onClick={openEnableVipModal}>升级VIP会员立享全站高清无限看</VipLink>}
        {isCoinVideoNeverBuy && <PaidLink onClick={openPurchaseVideoModal}>支付{product_coin}金币解锁完整版</PaidLink>}
        <VideoInfo>
          <Text>{title}</Text>
          <Info>
            {producer_name && (
              <InfoItem>
                片商：<InfoItemLink onClick={handleProducerClick}>{producer_name}</InfoItemLink>
              </InfoItem>
            )}
            {isDisplayVideoCode && <InfoItem>番号：{video_code}</InfoItem>}
            {validDisplayActorIds.length > 0 && (
              <InfoItem>
                演员：
                {validDisplayActorIds.map((i) => (
                  <ActorTag key={i} onClick={() => handleActorClick(state.actorInfo[i])}>
                    {state.actorInfo[i].name}
                  </ActorTag>
                ))}
              </InfoItem>
            )}
            {ong_video && (
              <InfoItem>
                原片：
                <InfoItemLink onClick={handleOriginalVideoClick}>链結</InfoItemLink>
              </InfoItem>
            )}
            {/* <InfoItem>觀看人數：{
              view_count > 10000
                ? (view_count / 1000).toFixed(1).replace(/\.0/, '') + 'k'
                : view_count
            }</InfoItem> */}
          </Info>

          <ShortcutArea>
            <ShortcutOrigin onClick={() => onToggleOriginList(!originListVisible)}>
              <ShortcutIcon name="globe.svg" />
              {selectedOfficialOriginName}
              <ShortcutIconDropDown name="arrow-down.svg" />
            </ShortcutOrigin>
            <Shortcut onClick={handelAddLikeList}>
              <StyledAnimatedIcon animationData={likeIcon} active={videoInfo.like === TRUE} />
              喜欢
            </Shortcut>
            <Shortcut onClick={handelAddCollectList}>
              <StyledAnimatedIcon animationData={saveIcon} active={videoInfo.favorite === TRUE} />
              收藏
            </Shortcut>
            <Shortcut onClick={handleShare}>
              <ShortcutIcon name="share.svg" />
              分享
            </Shortcut>
          </ShortcutArea>
          {originListVisible ? (
            <OriginList>
              {officialOriginList.map((item) => (
                <OriginItem
                  onClick={() => {
                    if (currentOfficialOrigin !== item.domain) {
                      dispatch(actions.updateOfficialOrigin(item.domain))
                      report({
                        SerialNumber: 10,
                        Event: 'video_play_click',
                        Trigger: '選擇線路',
                        Parameters: 'route_id',
                        Value: item.name,
                      })
                    }
                  }}
                  className={currentOfficialOrigin !== item.domain ? '' : 'active'}
                  key={item.domain}
                >
                  {item.name}
                </OriginItem>
              ))}
            </OriginList>
          ) : null}

          {/** 影片標籤 */}
          {!isEmpty(tag_ids) && (
            <TagArea>
              <TagAreaInner>
                {tag_ids
                  ?.map((tagId, i) => {
                    const info = tagList?.filter((tagInfo) => tagInfo.id === tagId)?.[0]

                    if (info == null) {
                      return null
                    }

                    return (
                      <TagItem key={i} onClick={() => openTag(info)}>
                        {info.name}
                      </TagItem>
                    )
                  })
                  .filter((i) => i)}
              </TagAreaInner>
            </TagArea>
          )}

          {pageActivated && <Carousel />}
        </VideoInfo>

        {pageActivated && <RelativeActorVideo videoInfo={videoInfo} />}

        {pageActivated && <RelativeCategoryVideo videoInfo={videoInfo} />}
      </Content>
    </Wrapper>
  )
}
